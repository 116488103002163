<template>
  <div class="important-page-c display--flex fd--column">
    <!-- 搜索 -->
    <div
      class="search-form btn-2b5a95"
    >
<div class="l-w" :style="{
        height: $store.state.global.isSearchExtend_1
          ? ''
          : $store.state.global.searchFormHeight_1 + 10 + 'px',
      }">
      <el-form
        label-position="right"
        :class="$store.state.global.isSearchExtend_1 ? '' : 'overflow--hidden'"
				:model="searchForm"
				@submit.native.prevent
      >
        <el-form-item label="考试名称:">
          <el-input 
						v-model.trim="searchForm.examname" 
						@keyup.enter.native="seach"
						clearable />
        </el-form-item>
				<el-form-item label="考试时长:">
				  <el-input 
						v-model.trim="searchForm.examinationtime" 
						@keyup.enter.native="seach"
						clearable />
				</el-form-item>
				<el-form-item label="教学点:">
				  <el-input 
						v-model.trim="searchForm.schoolorgname" 
						@keyup.enter.native="seach"
						clearable />
				</el-form-item>
				<el-form-item label="流水号:">
				  <el-input 
						v-model.trim="searchForm.id" 
						@keyup.enter.native="seach"
						clearable />
				</el-form-item>
      </el-form>
    </div>
      <div class="r-w">
      <el-button
        type="primary"
        icon="el-icon-search"
        @click="seach"
        :loading="!$store.state.global.isEndRequest"
        class="btn-2b5a95"
        >搜索</el-button
      >
			<el-button type="primary" @click="resetQuery">重置</el-button>
      <el-button
        type="primary"
        :icon="
          $store.state.global.isSearchExtend_1
            ? 'el-icon-arrow-up'
            : 'el-icon-arrow-down'
        "
        v-if="$store.state.global.searchExtend_1"
        class="btn-2b5a95"
        @click="onExtendClick('isSearchExtend_1')"
        >{{ $store.state.global.searchExtendText_1 }}</el-button
      >
    </div>

    </div>

    <!-- 按钮操作 -->
    <div
      class="Btn-w eaf4ff-btn"
      v-if="tableRes.buttonlist && tableRes.buttonlist.length"
      :style="{
        height: $store.state.global.isBtnExtend_1
          ? ''
          : $store.state.global.BtnHeight_1 + 10 + 'px',
      }"
    >
      <div
        class="btn-l"
        v-if="tableRes.buttonlist"
        :class="$store.state.global.isBtnExtend_1 ? '' : 'overflow--hidden'"
      >
        <el-button
          v-for="(item, index) in tableRes.buttonlist"
          :key="index"
          type="primary"
          @click="onClickBtn(item.buttonname)"
          >{{ item.name }}
        </el-button>
      </div>
      <div class="Btn-extend">
        <el-button
          type="primary"
          v-if="$store.state.global.btnExtend_1"
          class="btn-2b5a95"
          :icon="
            $store.state.global.isBtnExtend_1
              ? 'el-icon-arrow-up'
              : 'el-icon-arrow-down'
          "
          @click="onExtendClick('isBtnExtend_1')"
          >{{ $store.state.global.BtnExtendText_1 }}</el-button
        >
      </div>
    </div>

    <!-- 表格 -->
    <el-table
      :data="tableRes.list"
      stripe
      border
      :header-cell-style="{ background: '#044d8c', color: '#fff'}"
      ref="multipleTable"
      style="width: 100%"
      :cell-style="{padding:0}"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="55" />
			<el-table-column
        label="流水号"
        prop="id"
        align="center"
      />
      <el-table-column
        label="参考人"
        prop="examnameusername"
        align="center"
        width="200"
      />
			<el-table-column
			  label="考试名称"
			  prop="examname"
			  align="center"
			  width="200"
			/>
			
      <el-table-column
        label="考试时长"
        prop="examinationtime"
        align="center"
        width="80"
      />
			<el-table-column
			  label="开始考试"
			  prop="examtime"
			  align="center"
				>
				<template slot-scope="scope">
					{{  scope.row.examtime | formatDate('YYYY-MM-DD HH:mm:ss') }}
				</template>
			</el-table-column>
			<el-table-column
			  label="得分"
			  prop="score"
			  align="center"
			/>
			<el-table-column
			  label="教学点"
			  prop="schoolorgname"
			  align="center"
			/>
			<el-table-column
        label="操作"
        align="center"
				width="300"
      >
			<template slot-scope="scope" align="center">
				<el-button @click="btnPaperDetail(scope.row.id)" type="text">
						查看试卷
				</el-button>
          <el-button @click="btnShowDetail(scope.row.id)" type="text">
							开始考试
					</el-button>
        </template>
			</el-table-column>
     
    </el-table>
    <!-- 分页 -->
    <el-pagination
      background
      @current-change="handleCurrentChange"
      :current-page="pageinfo.pageindex"
      :page-size="pageinfo.pagesize"
      :page-sizes="[10, 20, 30, 40, 50]"
      layout="total, prev, pager, next, jumper"
      :total="tableRes.totalcount"
    ></el-pagination>
    <!--添加，修改-->
    <el-dialog
			class="btn-2b5a95"
			width="650px"
			v-dialogDrag
			:close-on-click-modal='false'
      :title="dialogTitle"
      :visible.sync="showAddModal"
      v-if="showAddModal"
      append-to-body
    >
      <el-form 
				:model="dynamicValidateForm" 
				ref="dynamicValidateForm" 
				class="demo-dynamic form-item-w-100"
				status-icon 
				:rules="rules"
				label-width="100px"
				>
				<el-form-item prop="companyorgname" label="所属分公司">
				  <el-select
				    v-model="dynamicValidateForm.companyorgname"
				    placeholder="请选择"
				    :disabled="!$store.state.login.isMaxAuth"
				    clearable
				    @change="onChangeCompany($event, 'dynamicValidateForm')"
				  >
				    <el-option
				      v-for="item in $store.state.selectData.companyList"
				      :key="item.key"
				      :label="item.val"
				      :value="item.key"
				    >
				    </el-option>
				  </el-select>
				</el-form-item>
				<el-form-item prop="schoolorgname" label="所属教学点">
				  <el-select
				    v-model="dynamicValidateForm.schoolorgname"
				    placeholder="请选择"
				    clearable
				    @change="onChangeSchool($event, 'dynamicValidateForm')"
				  >
				    <el-option
				      v-for="item in $store.state.selectData.schoolList"
				      :key="item.key"
				      :label="item.val"
				      :value="item.key"
				    >
				    </el-option>
				  </el-select>
				</el-form-item>
				<el-form-item label="参考人:" prop="examnameusername">
				  <el-input v-model="dynamicValidateForm.examnameusername" disabled />
				  <el-button
				    class="select-btn"
				    type="primary"
				    @click="
				      onSelectData(
								user_dialoguerrsult6,
				        'showCollegenameModal',
				        null
				      )
				    "
				    >选择</el-button
				  >
				</el-form-item>
				<el-form-item label="组卷:" prop="examname">
				  <el-input v-model="dynamicValidateForm.examname" disabled />
				  <el-button
				    class="select-btn"
				    type="primary"
				    @click="
				      onSelectData(
								examsettings_dialogresult,
				        'showexamModal',
				        null
				      )
				    "
				    >选择</el-button
				  >
				</el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          @click="submitAddOrEditForm('dynamicValidateForm')"
          :loading="!$store.state.global.isEndRequest"
          >提交
        </el-button>
      </span>
    </el-dialog>
		<!-- 开始考试详情 -->
		<el-drawer
		  :visible.sync="showDetailModal"
			v-if="showDetailModal"
			size="100%"
		  :with-header="false"
			:show-close="false"
			:append-to-body="false"
			:modal-append-to-body="false"
			:wrapper-closable="false"
			>
			<div class="header-wrapper" slot="title">
				<div class="inner">
					<el-row>
						<el-col :span="18">
							<div class="grid-content bg-purple ovf left display--flex ai--center">
								<div class="logo title-name">
									<img src="../../../assets/image/login-logo.png"/>
								</div>
								<div class="index">业务考核试卷</div>
							</div>
						</el-col>
						<el-col :span="6">
							<el-button type="primary" size="medium" round @click="showDetailModal = false">退出</el-button>
						</el-col>
					</el-row>
				</div>
			</div>
			<div class="exam">
				<el-form
					:model="aminationForm"
					ref="aminationForm" 
					class="demo-dynamic form-item-w-100"
					status-icon 
					:rules="rules"
				>
			    <div class="main">
			      <div class="mian-body">
			        <div class="main-side">
			          <div class="title">
			            <div class="title_border"></div>
			            <div class="title_content">答题卡</div>
			          </div>
			          <div class="card-content-list">
									<!-- 单选题 -->
			            <div class="card-content" v-if="aminationForm.list1.length > 0">
			              <div class="card-content-title">单选题(共1题，合计1分)</div>
			              <div class="box-list">
			                <div
			                  class="box normal-box question_cbox"
			                  v-for="(item,index) in aminationForm.list1"
			                  :key="index"
			                >
			                  <div
			                    class="checki"
			                    :class="{'checked':radio[index]}"
			                    v-show="!checkResult"
			                  >{{index+1}}</div>
			                  <div
			                    class="checki checked"
			                    :class="{'check-error':radio[index]}"
			                    v-show="checkResult"
			                  >{{index+1}}</div>
			                </div>
			              </div>
			            </div>
									<!-- 多选题 -->
									<div class="card-content" v-if="aminationForm.list2.length > 0">
									  <div class="card-content-title">多选题(共1题，合计1分)</div>
									  <div class="box-list">
									    <div
									      class="box normal-box question_cbox"
									      v-for="(item,index) in aminationForm.list2"
									      :key="index"
									    >
									     <div class="checki" v-if="item.selChoice.length == '0'">{{index+1}}</div>
									      <div v-else class="checki checked">{{index+1}}</div>
									    </div>
									  </div>
									</div>
									
									<!-- 简答题 -->
									<div class="card-content" v-if="aminationForm.list3.length > 0">
									  <div class="card-content-title">简答题(共1题，合计1分)</div>
									  <div class="box-list">
									    <div
									      class="box normal-box question_cbox"
									      v-for="(item,index) in aminationForm.list3"
									      :key="index"
									    >
									      <div
									        class="checki"
									        :class="{'checked':checkInput[index]}"
									        v-show="!checkResult"
									      >{{index+1}}</div>
									      <div
									        class="checki checked"
									        :class="{'check-error':checkInput[index]}"
									        v-show="checkResult"
									      >{{index+1}}</div>
									    </div>
									  </div>
									</div>
									
									<!--填空题 -->
									<div class="card-content" v-if="aminationForm.list4.length > 0">
									  <div class="card-content-title">填空题(共1题，合计1分)</div>
									  <div class="box-list">
									    <div
									      class="box normal-box question_cbox"
									      v-for="(item,index) in aminationForm.list4"
									      :key="index"
									    >
									      <div
									        class="checki"
									        :class="{'checked':completion[index]}"
									        v-show="!checkResult"
									      >{{index+1}}</div>
									      <div
									        class="checki checked"
									        :class="{'check-error':completion[index]}"
									        v-show="checkResult"
									      >{{index+1}}</div>
									    </div>
									  </div>
									</div>
									
									<!-- 判断题 -->
									<div class="card-content" v-if="aminationForm.list5.length > 0">
									  <div class="card-content-title">判断题(共1题，合计1分)</div>
									  <div class="box-list">
									    <div
									      class="box normal-box question_cbox"
									      v-for="(item,index) in aminationForm.list5"
									      :key="index"
									    >
									      <div
									        class="checki"
									        :class="{'checked':errortion[index]}"
									        v-show="!checkResult"
									      >{{index+1}}</div>
									      <div
									        class="checki checked"
									        :class="{'check-error':errortion[index]}"
									        v-show="checkResult"
									      >{{index+1}}</div>
									    </div>
									  </div>
									</div>
								
								
								</div>
			        </div>
							
						
			        <div class="main-center">
								<!-- 单选 -->
			          <div class="body-wrapper"  v-if="aminationForm.list1.length > 0">
			            <div class="questions">
			              <div class="questions-title">
											{{aminationForm.ttype1}}(每题{{aminationForm.questionscores1}}分，合计{{aminationForm.questionscore1}}分)
										</div>
			              <div class="questions-content">
			                <div class="question-content" v-for="(item,i) in aminationForm.list1" :key="i">
			                  
			                  <div v-if="item.ttype == '选择题'">
			                    <div class="exam-question">
			                      <span class="question-index ellipsis">{{i+1}}.</span>
			                      {{item.title}}
			                    </div>
			                    <div v-if="!checking">
			                      <div class="answers" v-for="(son,index) in item.contentlist" :key="index">
																<el-radio-group v-model="radio[i]">
																 <el-radio
																		style="flex-wrap: wrap;margin-bottom: 20px; white-space: normal; line-height: 22px;"
																    v-model="radio[i]"
																    :label="son"
																    @change="getIputValue(i)"
																  >
																	{{son}}
																	
																	</el-radio>
																</el-radio-group>
			                      </div>
			                    </div>
			                    <div v-else>
			                      <div class="answers" v-for="(son,index) in item.contentlist" :key="index">
			                        <el-radio-group v-model="checkData[i].value">
			                          <el-radio
																	style="flex-wrap: wrap;margin-bottom: 20px; white-space: normal; line-height: 22px;"
			                            v-model="radio[i]"
			                            :label="son"
			                            @change="getIputValue(i)"
			                          >
																{{son}}
																
																</el-radio>
			                        </el-radio-group>
			                      </div>
			                    </div>
			                  </div>
											</div>
			              </div>
			            </div>
			          </div>
								
								
								
								<!-- 多选 -->
							<div class="body-wrapper" v-if="aminationForm.list2.length > 0">
							  <div class="questions">
							    <div class="questions-title">
										{{aminationForm.ttype2}}(每题{{aminationForm.questionscores2}}分，合计{{aminationForm.questionscore2}}分)
									</div>
							    <div class="questions-content">
							      <div class="question-content" v-for="(item,i) in aminationForm.list2" :key="i">
							        
							        <div v-if="item.ttype == '多项选择题'">
							          <div class="exam-question">
							            <span class="question-index ellipsis">{{i+1}}.</span>
							            {{item.title}}
							          </div>
							          <div v-if="!checking">
							            <div class="answers">
														<el-checkbox-group
															class="fd--column display--flex"
															v-model="item.selChoice"
															>
														  <el-checkbox
																class="checkboxAll"
																style="width: 100%; margin-bottom: 20px;"
														    v-for="(city,index) in item.contentlist"
														    :label="city.substring(0, city.indexOf('.'), city.length)"
														    :key="index"
																@change="getcheckValue(i, city)"
														  > {{city}} </el-checkbox>
														</el-checkbox-group>
							            </div>
							          </div>
							          <div v-else>
							         <div class="answers">
														<el-checkbox-group
															v-model="item.selChoice"
															class="fd--column display--flex"
															>
															<el-checkbox
																class="checkboxAll"
																style="width: 100%; margin-bottom: 20px;"
																v-for="(city,index) in item.contentlist"
																:label="city.substring(0, city.indexOf('.'), city.length)"
																:key="index"
																@change="getcheckValue(i, city)"
															>{{city}}</el-checkbox>
														</el-checkbox-group>
							            </div>
							          </div>
							        </div>
										</div>
							    </div>
							  </div>
							</div>
							
							<!-- 简答题 -->
							<div class="body-wrapper" v-if="aminationForm.list3.length > 0">
							  <div class="questions">
							    <div class="questions-title">
										{{aminationForm.ttype3}}(每题{{aminationForm.questionscores3}}分，合计{{aminationForm.questionscore3}}分)
									</div>
							    <div class="questions-content">
							      <div class="question-content" v-for="(item,i) in aminationForm.list3" :key="i">
							        
							        <div v-if="item.ttype == '简答题'">
							          <div class="exam-question">
							            <span class="question-index ellipsis">{{i+1}}.</span>
							            {{item.title}}
							          </div>
							          <div v-if="!checking">
							            <div class="answers">
							              <el-input
							              	type="textarea" 
															v-model="checkInput[i]"
															@input="getInput(i)"
							              	:autosize="{ minRows: 5, maxRows: 5}" 
							              	placeholder="请输入..."
							              	style="padding-top: 10px"></el-input>
							            </div>
							          </div>
							          <div v-else>
							            <div class="answers">
							             <el-input
							              	type="textarea" 
															v-model="checkInputData[i].value"
															@input="getInput(i)"
							              	:autosize="{ minRows: 5, maxRows: 5}" 
							              	placeholder="请输入..."
							              	style="padding-top: 10px"></el-input>
							            </div>
							          </div>
							        </div>
										</div>
							    </div>
							  </div>
							</div>
							
							<!-- 填空题 -->
							<div class="body-wrapper" v-if="aminationForm.list4.length > 0">
							  <div class="questions">
							    <div class="questions-title">
										{{aminationForm.ttype4}}(每题{{aminationForm.questionscores4}}分，合计{{aminationForm.questionscore4}}分)
									</div>
							    <div class="questions-content">
							      <div class="question-content" v-for="(item,i) in aminationForm.list4" :key="i">
							        
							        <div v-if="item.ttype == '填空题'">
							          <div class="exam-question">
							            <span class="question-index ellipsis">{{i+1}}.</span>
							            {{item.title}}
							          </div>
							          <div v-if="!checking">
							            <div class="answers">
							              <el-input
															v-model="completion[i]"
							              	type="textarea" 
															@input="handleComple(i)"
							              	:autosize="{ minRows: 5, maxRows: 5}" 
							              	placeholder="请输入..."
							              	style="padding-top: 10px"></el-input>
							            </div>
							          </div>
							          <div v-else>
							            <div class="answers">
							             <el-input
							              	type="textarea" 
															@input="handleComple(i)"
															v-model="completionData[i].value"
							              	:autosize="{ minRows: 5, maxRows: 5}" 
							              	placeholder="请输入..."
							              	style="padding-top: 10px"></el-input>
							            </div>
							          </div>
							        </div>
										</div>
							    </div>
							  </div>
							</div>
							
							<!-- 判断题 -->
							<div class="body-wrapper" v-if="aminationForm.list5.length > 0">
							  <div class="questions">
							    <div class="questions-title">
										{{aminationForm.ttype5}}(每题{{aminationForm.questionscores5}}分，合计{{aminationForm.questionscore5}}分)
									</div>
							    <div class="questions-content">
							      <div class="question-content" v-for="(item,i) in aminationForm.list5" :key="i">
							        
							        <div v-if="item.ttype == '判断题'">
							          <div class="exam-question">
							            <span class="question-index ellipsis">{{i+1}}.</span>
							            {{item.title}}
							          </div>
							          <div v-if="!checking">
							            <div class="answers" v-for="(son,index) in item.contentlist" :key="index">
							              <el-radio-group v-model="errortion[i]">
							               <el-radio
							                  v-model="errortion[i]"
							                  :label="son"
							                  :name="son"
							                  @change="geterror(i)"
							                >{{son}}</el-radio>
							              </el-radio-group>
							            </div>
							          </div>
							          <div v-else>
							            <div class="answers" v-for="(son,index) in item.contentlist" :key="index">
							              <el-radio-group v-model="errortionData[i].value">
							                <el-radio
							                  v-model="errortion[i]"
							                  :label="son"
							                  :name="son"
							                  @change="geterror(i)"
							                >{{son}}</el-radio>
							              </el-radio-group>
							            </div>
							          </div>
							        </div>
										</div>
							    </div>
							  </div>
							</div>
							</div>
			        <div class="main-right">
			          <div class="btn" v-show="!checkResult" @click="submitHandleTest('aminationForm')">提交</div>
			        </div>
			      </div>
			    </div>
				</el-form>
				</div>
		</el-drawer>
		<!-- 题型新增/修改 -->
		<el-dialog
      class="btn-2b5a95"
      width="60%"
			v-dialogDrag
			:close-on-click-modal='false'
      @close="onCloseSelect"
      :title="dialogTitle"
      :visible.sync="addShowAddModal"
      v-if="addShowAddModal"
      append-to-body
    >
			<el-form
				:model="dynamicValidateForm" 
				ref="dynamicValidateForm" 
				class="demo-dynamic form-item-w-100"
				status-icon 
				:rules="rules"
				label-width="100px"
				>
				<el-form-item label="题目类型:" prop="ttype">
				  <el-select 
						v-model="dynamicValidateForm.ttype" 
						clearable>
				    <el-option
							v-for="question in questiontypeList"
							:key="question.key"
							:label="question.val"
							:value="question.key">
						</el-option>
				  </el-select>
				</el-form-item>
				<el-form-item label="单题分值:" prop="questionscore">
			    <el-input 
						v-model.trim="dynamicValidateForm.questionscore" />
			  </el-form-item>
				<el-form-item label="题目数量:" prop="questionquantity">
			    <el-input 
						v-model.trim="dynamicValidateForm.questionquantity" />
			  </el-form-item>
				<el-form-item label="排序:" prop="sort">
				  <el-input 
						v-model.trim="dynamicValidateForm.sort" />
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
			  <el-button
			    type="primary"
			    @click="submitaddForm('dynamicValidateForm')"
			    :loading="!$store.state.global.isEndRequest"
			    >提交
			  </el-button>
			</span>
    </el-dialog>
		<!--选择参考人-->
		<el-dialog
		  class="btn-2b5a95"
		  width="1200px"
			v-dialogDrag
			:close-on-click-modal='false'
		  @close="onCloseSelect"
		  title="选择参考人："
		  :visible.sync="showCollegenameModal"
		  v-if="showCollegenameModal"
		  append-to-body
		>
			<el-button type="primary" @click="onConfirmquestionname">确定</el-button>
		 <div class="index_2 btn-2b5a95">
		    <div
		      class="l-w"
		      :style="{
		        height: $store.state.global.isSearchExtend_2
		          ? ''
		          : $store.state.global.searchFormHeight_2 + 10 + 'px',
		      }"
		    >
		      <el-form
		        label-position="right"
		        :class="
		          $store.state.global.isSearchExtend_2 ? '' : 'overflow--hidden'
		        "
		        :model="selectSearchForm"
						@submit.native.prevent
		      >
		        <el-form-item label="姓名:" prop="realname">
		          <el-input 
								v-model.trim="selectSearchForm.realname" 
								@keyup.enter.native="onSelectSearchClick(selectTableUrl,{}, selectSearchForm)" clearable/>
		        </el-form-item>
		      </el-form>
		    </div>
		    <div class="r-w">
		      <el-button
		        type="primary"
		        icon="el-icon-search"
		        :loading="!$store.state.global.isEndRequest"
		        class="btn-2b5a95"
						 @click="onSelectSearchClick(selectTableUrl,{}, selectSearchForm)"
		        >搜索</el-button
		      >
					<el-button type="primary" @click="handleQuery">重置</el-button>
		      <el-button
		        type="primary"
		        :icon="
		          $store.state.global.isSearchExtend_2
		            ? 'el-icon-arrow-up'
		            : 'el-icon-arrow-down'
		        "
		        v-if="$store.state.global.searchExtend_2"
		        class="btn-2b5a95"
		        @click="onExtendClick('isSearchExtend_2')"
		        >{{ $store.state.global.searchExtendText_2 }}</el-button
		      >
		    </div>
		  </div>
		  <el-table
		    :data="selectTable.list"
		    style="width: 100%"
		    ref="moneyTable"
				stripe
				border
				:header-cell-style="{ background: '#044d8c', color: '#fff'}"
		    @selection-change="handleSelectionChange"
		  >
		    <el-table-column type="selection" width="55" />
		    <el-table-column label="流水号" prop="id" align="center" />
		    <el-table-column label="姓名" prop="realname" align="center" />
				<el-table-column label="教学点" prop="schoolorgname" align="center" />
		  </el-table>
		  <el-pagination
				background
				@current-change="
				 (val, data) =>
				   handleSelectChange(val, Object.assign({}, selectSearchForm))
				"
		    :current-page="selectPageInfo.pageindex"
		    :page-size="selectPageInfo.pagesize"
		    :page-sizes="[10, 20, 30, 40, 50]"
		    layout="total, prev, pager, next, jumper"
		    :total="selectTable.totalcount"
		  ></el-pagination>
		</el-dialog>
		<!-- 选择组卷 -->
		<el-dialog
		  class="btn-2b5a95"
		  width="1200px"
			v-dialogDrag
			:close-on-click-modal='false'
		  @close="onCloseSelect"
		  title="选择组卷："
		  :visible.sync="showexamModal"
		  v-if="showexamModal"
		  append-to-body
		>
			<el-button type="primary" @click="onConfirmexamname">确定</el-button>
		 <div class="index_2 btn-2b5a95">
		    <div
		      class="l-w"
		      :style="{
		        height: $store.state.global.isSearchExtend_2
		          ? ''
		          : $store.state.global.searchFormHeight_2 + 10 + 'px',
		      }"
		    >
		      <el-form
		        label-position="right"
		        :class="
		          $store.state.global.isSearchExtend_2 ? '' : 'overflow--hidden'
		        "
		        :model="selectSearchForm"
						@submit.native.prevent
		      >
		        <el-form-item label="题库:">
		          <el-input 
								v-model.trim="selectSearchForm.examname" 
								@keyup.enter.native="onSelectSearchClick(selectTableUrl,{}, selectSearchForm)" clearable/>
		        </el-form-item>
						<el-form-item label="题库:">
						  <el-input 
								v-model.trim="selectSearchForm.questionname" 
								@keyup.enter.native="onSelectSearchClick(selectTableUrl,{}, selectSearchForm)" clearable/>
						</el-form-item>
		      </el-form>
		    </div>
		    <div class="r-w">
		      <el-button
		        type="primary"
		        icon="el-icon-search"
		        :loading="!$store.state.global.isEndRequest"
		        class="btn-2b5a95"
						 @click="onSelectSearchClick(selectTableUrl,{}, selectSearchForm)"
		        >搜索</el-button
		      >
					<el-button type="primary" @click="handleQuery">重置</el-button>
		      <el-button
		        type="primary"
		        :icon="
		          $store.state.global.isSearchExtend_2
		            ? 'el-icon-arrow-up'
		            : 'el-icon-arrow-down'
		        "
		        v-if="$store.state.global.searchExtend_2"
		        class="btn-2b5a95"
		        @click="onExtendClick('isSearchExtend_2')"
		        >{{ $store.state.global.searchExtendText_2 }}</el-button
		      >
		    </div>
		  </div>
		  <el-table
		    :data="selectTable.list"
		    style="width: 100%"
		    ref="moneyTable"
				stripe
				border
				:header-cell-style="{ background: '#044d8c', color: '#fff'}"
		    @selection-change="handleSelectionChange"
		  >
		    <el-table-column type="selection" width="55" />
		    <el-table-column label="流水号" prop="id" align="center" />
		    <el-table-column label="考试名称" prop="examname" align="center" />
				<el-table-column label="考试时长" prop="examinationtime" align="center" />
				<el-table-column label="题库名称" prop="questionname" align="center" />
				<el-table-column label="录入人" prop="insertusername" align="center" />
				<el-table-column label="教学点" prop="schoolorgname" align="center" />
		  </el-table>
		  <el-pagination
				background
				@current-change="
				 (val, data) =>
				   handleSelectChange(val, Object.assign({}, selectSearchForm))
				"
		    :current-page="selectPageInfo.pageindex"
		    :page-size="selectPageInfo.pagesize"
		    :page-sizes="[10, 20, 30, 40, 50]"
		    layout="total, prev, pager, next, jumper"
		    :total="selectTable.totalcount"
		  ></el-pagination>
		</el-dialog>
		
		<!-- 改卷===试卷 -->
		<el-drawer
		 :visible.sync="scoreAddModal"
		  :show-close="false"
			size="100%"
			:with-header="false"
			:append-to-body="true"
			:modal-append-to-body="false"
		  >
			  <div slot="title" class="demo-drawer__header">
			    <span class="title-name">业务考核试卷</span>
					<div>
						<p class="fenshu">100分</p>
						<img src="../../../assets/image/scorbg.png"/>
					</div>
			  </div>
				<div class="wts-container">
		      <div class="do-exercise">
		        <div style="width: 60%">
							<div class="exercise-up-page" @click="scoreAddModal = false">
								<img  src="../../../assets/image/back.png" style="margin-right: 5px;width: 22px;height: 18px;"/>
								返回上一页
							</div>
							<el-form
								:model="dynamicValidateForm"
								ref="dynamicValidateForm" 
								class="demo-dynamic form-item-w-100"
								status-icon 
								:rules="rules"
							>
							<!-- 单选 -->
		          <div class="exercise-center"  v-if="dynamicValidateForm.ttype1 && dynamicValidateForm.questionscores1 != null">
		                      <!--题目信息-->
								<div style="height: 80px">
									<template >
		                <el-row>
		                    <el-col :span="2" style="padding-left: 23px;">
		                        <div style="width: 56px;height: 56px;">
															<img src="../../../assets/image/favicon.png" style="width: 100%;"/>
														</div>
		                    </el-col>
		                    <el-col :span="22" style="padding-top: 2px; padding-left: 16px">
		                        <el-row>
		                            <el-col :span="5">题目类型：<span class="topic-style">{{dynamicValidateForm.ttype1}}</span></el-col>
		                        </el-row>
		                        <el-row class="topic-information">
		                            <el-col :span="5">每题({{dynamicValidateForm.questionscores1}})数共({{dynamicValidateForm.questionscore1}})分</el-col>
		                        </el-row>
		                    </el-col>
		                </el-row>
									</template>
		            </div>
		            <!--具体题目内容-->
		            <template class="topicContent" v-for="(item, i) in dynamicValidateForm.list1">
		              <!--单选题-->
									<div class="radio-type">
											<div style="padding-bottom: 5px;"><span class="question-index">{{i+1}}.</span>({{item.score}}分) {{item.title}}</div>
											<el-radio-group 
													v-for="index in item.contentlist" 
													:key="index">
													<el-radio 
														:label="index" 
														style="flex-wrap: wrap;margin-bottom: 20px; white-space: normal; line-height: 22px;"
														disabled>{{index}}</el-radio>
											</el-radio-group>
									</div>
									<div class="button-style">
										<el-form-item 
											:prop="'list1.' + i + '.scores'"
											:rules="{
											  required: true,
											  message: '必填字段',
											  trigger: 'blur',
											}"
											>
											<el-input
												class="text" 
												placeholder="请输入分数"
												style="width: 15%;"
												v-model="item.scores"
												size="medium"
												suffix-icon="el-icon-edit el-input__icon"
												></el-input>
										</el-form-item>
										<br>
									<div class="btn-div"><el-tag>我的答案</el-tag>{{item.answer2}}<br></div>
									<div class="btn-div"><el-tag type="info">正确答案</el-tag>{{item.answer}}<br></div>
									<div class="btn-div"><el-tag type="warning">查看解析</el-tag>{{item.reference}}</div>
									</div>
		            </template>
		          </div>
							<!-- 多选 -->
							<div class="exercise-center" v-if="dynamicValidateForm.ttype2 && dynamicValidateForm.questionscores2 != null">
							            <!--题目信息-->
								<div style="height: 80px">
									<template >
							      <el-row>
							          <el-col :span="2" style="padding-left: 23px;">
							              <div style="width: 56px;height: 56px;">
															<img src="../../../assets/image/favicon.png" style="width: 100%;"/>
														</div>
							          </el-col>
							          <el-col :span="22" style="padding-top: 2px; padding-left: 16px">
							              <el-row>
							                  <el-col :span="5">题目类型：<span class="topic-style">{{dynamicValidateForm.ttype2}}</span></el-col>
							              </el-row>
							              <el-row class="topic-information">
							                  <el-col :span="5">每题({{dynamicValidateForm.questionscores2}})数共({{dynamicValidateForm.questionscore2}})分</el-col>
							              </el-row>
							          </el-col>
							      </el-row>
									</template>
							  </div>
							  <!--具体题目内容-->
							  <template class="topicContent"  v-for="(item, i) in dynamicValidateForm.list2">
							    <!--多选题-->
							    <div class="answer-type">
										<div style="padding-bottom: 5px;"><span class="question-index">{{i+1}}.</span>({{item.score}}分) {{item.title}}</div>
										<el-checkbox-group
												class="fd--column display--flex"
												v-model="multiValue"
												v-for="cores in item.contentlist" 
												:key="cores">
												<el-checkbox :label="cores" disabled style="width: 100%; margin-bottom: 20px;">{{cores}}</el-checkbox> 
										</el-checkbox-group>
							    </div>
									<div class="button-style">
										<el-form-item
											:prop="'list2.' + i + '.scores'"
											:rules="{
											  required: true,
											  message: '必填字段',
											  trigger: 'blur',
											}"
										>
											<el-input
												class="text" 
												style="width: 15%;"
												placeholder="请输入分数"
												v-model="item.scores"
												size="medium"
												suffix-icon="el-icon-edit el-input__icon"
												></el-input>
										</el-form-item>
										<br>
									<div class="btn-div"><el-tag>我的答案</el-tag>{{item.answer2}}<br></div>
									<div class="btn-div"><el-tag type="info">正确答案</el-tag>{{item.answer}}<br></div>
									<div class="btn-div"><el-tag type="warning">查看解析</el-tag>{{item.reference}}</div>
									</div> 
							  </template>
							</div>
							
								<!-- 填空题 -->
							<div class="exercise-center" v-if="dynamicValidateForm.ttype3 && dynamicValidateForm.questionscores3 != null">
							            <!--题目信息-->
								<div style="height: 80px">
									<template >
							      <el-row>
							          <el-col :span="2" style="padding-left: 23px;">
							              <div style="width: 56px;height: 56px;">
															<img src="../../../assets/image/favicon.png" style="width: 100%;"/>
														</div>
							          </el-col>
							          <el-col :span="22" style="padding-top: 2px; padding-left: 16px">
							              <el-row>
							                  <el-col :span="5">题目类型：<span class="topic-style">{{dynamicValidateForm.ttype3}}</span></el-col>
							              </el-row>
							              <el-row class="topic-information">
							                  <el-col :span="5">每题({{dynamicValidateForm.questionscores3}})数共({{dynamicValidateForm.questionscore3}})分</el-col>
							              </el-row>
							          </el-col>
							      </el-row>
									</template>
							  </div>
							  <!--具体题目内容-->
							  <template class="topicContent"  v-for="(item, i) in dynamicValidateForm.list3">
							    <!--简答题-->
							    <div class="answer-type">
										<div style="padding-bottom: 5px;"><span class="question-index">{{i+1}}.</span>({{item.score}}分) {{item.title}}</div>
										<el-form-item 
											:prop="'list3.' + i + '.scores'"
											:rules="{
											  required: true,
											  message: '必填字段',
											  trigger: 'blur',
											}"
										>
											<el-input
												type="textarea" 
												:autosize="{ minRows: 5, maxRows: 5}" 
												placeholder="请输入..."
												disabled
												style="padding-top: 10px"></el-input>
										</el-form-item>
							      
							     </div>
									<div class="button-style">
										<el-input
											style="width: 15%;"
											class="text" 
											placeholder="请输入分数"
											v-model="item.scores"
											size="medium"
											suffix-icon="el-icon-edit el-input__icon"
											></el-input>
										<br>
									<div class="btn-div"><el-tag>我的答案</el-tag>{{item.answer2}}<br></div>
									<div class="btn-div"><el-tag type="info">正确答案</el-tag>{{item.answer}}<br></div>
									<div class="btn-div"><el-tag type="warning">查看解析</el-tag>{{item.reference}}</div>
									</div>
							  </template>
							</div>
							
							<div class="exercise-center" v-if="dynamicValidateForm.ttype4 && dynamicValidateForm.questionscores4 != null">
							            <!--题目信息-->
								<div style="height: 80px" >
									<template>
							      <el-row>
							          <el-col :span="2" style="padding-left: 23px;">
							              <div style="width: 56px;height: 56px;">
															<img src="../../../assets/image/favicon.png" style="width: 100%;"/>
														</div>
							          </el-col>
							          <el-col :span="22" style="padding-top: 2px; padding-left: 16px">
							              <el-row>
							                  <el-col :span="5">题目类型：<span class="topic-style">{{dynamicValidateForm.ttype4}}</span></el-col>
							              </el-row>
							              <el-row class="topic-information">
							                  <el-col :span="5">每题({{dynamicValidateForm.questionscores4}})数共({{dynamicValidateForm.questionscore4}})分</el-col>
							              </el-row> 
							          </el-col>
							      </el-row>
									</template>
							  </div>
							  <!--具体题目内容-->
							  <template class="topicContent"  v-for="(item, i) in dynamicValidateForm.list4">
							    <!--填空题-->
							    <div class="answer-type">
										<div style="padding-bottom: 5px;"><span class="question-index">{{i+1}}.</span>({{item.score}}分) {{item.title}}</div>
							      <el-input 
											type="textarea" 
											:autosize="{ minRows: 5, maxRows: 5}" 
											placeholder="请输入..."
											disabled
											style="padding-top: 10px"></el-input>
							     </div>
									<div class="button-style">
										<el-form-item 
											:prop="'list4.' + i + '.scores'"
											:rules="{
											  required: true,
											  message: '必填字段',
											  trigger: 'blur',
											}"
										>
											<el-input
												style="width: 15%;"
												class="text" 
												v-model="item.scores"
												size="medium"
												placeholder="请输入分数"
												suffix-icon="el-icon-edit el-input__icon"
												></el-input>
										</el-form-item>
										<br>
									<div class="btn-div"><el-tag>我的答案</el-tag>{{item.answer2}}<br></div>
									<div class="btn-div"><el-tag type="info">正确答案</el-tag>{{item.answer}}<br></div>
									<div class="btn-div"><el-tag type="warning">查看解析</el-tag>{{item.reference}}</div>
									</div>
							  </template>
							</div>
							
							<div class="exercise-center" v-if="dynamicValidateForm.ttype5 && dynamicValidateForm.questionscores5 != null">
							            <!--题目信息-->
								<div style="height: 80px">
									<template>
							      <el-row>
							          <el-col :span="2" style="padding-left: 23px;">
							              <div style="width: 56px;height: 56px;">
															<img src="../../../assets/image/favicon.png" style="width: 100%;"/>
														</div>
							          </el-col>
							          <el-col :span="22" style="padding-top: 2px; padding-left: 16px">
							              <el-row>
							                  <el-col :span="5">题目类型：<span class="topic-style">{{dynamicValidateForm.ttype5}}</span></el-col>
							              </el-row>
							              <el-row class="topic-information">
							                  <el-col :span="5">每题({{dynamicValidateForm.questionscores5}})数共({{dynamicValidateForm.questionscore5}})分</el-col>
							              </el-row>
							          </el-col>
							      </el-row>
									</template>
							  </div>
							  <!--具体题目内容-->
							  <div class="topicContent"  v-for="(item, i) in dynamicValidateForm.list5">
							    <!--判断题题-->
									<div class="radio-type">
											<div style="padding-bottom: 5px;"><span class="question-index">{{i+1}}.</span>({{item.score}}分) {{item.title}}</div>
											<el-radio-group 
													v-for="index in item.contentlist" 
													:key="index">
													<el-radio :label="index" disabled>{{index}}</el-radio>
											</el-radio-group>
									</div>
									<div class="button-style">
										<el-form-item
											:prop="'list5.' + i + '.scores'"
											:rules="{
											  required: true,
											  message: '必填字段',
											  trigger: 'blur',
											}"	
											>
											<el-input
												class="text" 
												style="width: 15%;"
												v-model="item.scores"
												size="medium"
												placeholder="请输入分数"
												suffix-icon="el-icon-edit el-input__icon"
												></el-input>
											</el-form-item>
										<br>
										<div class="btn-div"><el-tag>我的答案</el-tag>{{item.answer2}}<br></div>
										<div class="btn-div"><el-tag type="info">正确答案</el-tag>{{item.answer}}<br></div>
										<div class="btn-div"><el-tag type="warning">查看解析</el-tag>{{item.reference}}</div>
									</div>
							  </div>
							</div>
							
							<el-button 
								@click="submitFormTest('dynamicValidateForm')" 
								type="primary" 
								round 
								style="width: 100%;
								margin-top: 20px;
								margin-bottom: 20px;"
								>提交</el-button>
							</el-form>
		        </div>
					</div>
				</div>
		</el-drawer>
		<!-- 查看试卷 -->
		<el-drawer
		 :visible.sync="showTestModal"
		  :show-close="false"
			size="100%"
			:with-header="false"
			:append-to-body="true"
			:modal-append-to-body="false"
		  >
			  <div slot="title" class="demo-drawer__header">
					<div class="exercise-up-page" @click="showTestModal= false">
						<img  src="../../../assets/image/back.png" style="margin-right: 5px;width: 22px;height: 18px;"/>
						返回上一页
					</div>
			  </div>
				<div class="wts-container">
		      <div class="do-exercise">
		        <div style="width: 60%">
							
							<!-- 单选 -->
		          <div class="exercise-center" v-if="dynamicValidateForm.list1 > 0">
		                      <!--题目信息-->
								<div style="height: 80px">
									<template>
		                <el-row>
		                    <el-col :span="2" style="padding-left: 23px;">
		                        <div style="width: 56px;height: 56px;">
															<img src="../../../assets/image/favicon.png" style="width: 100%;"/>
														</div>
		                    </el-col>
		                    <el-col :span="22" style="padding-top: 2px; padding-left: 16px">
		                        <el-row>
		                            <el-col :span="5">题目类型：<span class="topic-style">{{dynamicValidateForm.ttype1}}</span></el-col>
		                        </el-row>
		                        <el-row class="topic-information">
		                            <el-col :span="5">每题({{dynamicValidateForm.questionscores1}})分共({{dynamicValidateForm.questionscore1}})分</el-col>
		                        </el-row>
		                    </el-col>
		                </el-row>
									</template>
		            </div>
		            <!--具体题目内容-->
		            <template class="topicContent" v-for="(item, i) in dynamicValidateForm.list1">
		                          <!--单选题-->
									<div class="radio-type">
										<div style="padding-bottom: 5px;"> 
											<span class="question-index">{{i+1}}.</span>({{item.score}}分) {{item.title}}
										</div>
										<el-radio-group 
											class="answers"
											v-model="radioValue"
											v-for="index in item.contentlist" 
											:key="index">
											<el-radio 
												:label="index" 
												style="flex-wrap: wrap; white-space: normal; line-height: 22px;"
												disabled>{{index}}</el-radio>
										</el-radio-group>
									</div>
									<div class="button-style">
										<div class="btn-div"><el-tag>我的答案</el-tag>{{item.answer2}}<br></div>
										<div class="btn-div"><el-tag type="info">正确答案</el-tag>{{item.answer}}<br></div>
										<div class="btn-div"><el-tag type="warning">查看解析</el-tag>{{item.reference}}</div>
									</div>
		            </template>
		          </div>
							<!-- 多选 -->
							<div class="exercise-center" v-if="dynamicValidateForm.list2 > 0">
							            <!--题目信息-->
								<div style="height: 80px">
									<template>
							      <el-row>
							          <el-col :span="2" style="padding-left: 23px;">
							              <div style="width: 56px;height: 56px;">
															<img src="../../../assets/image/favicon.png" style="width: 100%;"/>
														</div>
							          </el-col>
							          <el-col :span="22" style="padding-top: 2px; padding-left: 16px">
							              <el-row>
							                  <el-col :span="5">题目类型：<span class="topic-style">{{dynamicValidateForm.ttype2}}</span></el-col>
							              </el-row>
							              <el-row class="topic-information">
							                  <el-col :span="5">每题({{dynamicValidateForm.questionscores2}})分共({{dynamicValidateForm.questionscore2}})分</el-col>
							              </el-row>
							          </el-col>
							      </el-row>
									</template>
							  </div>
							  <!--具体题目内容-->
							  <template class="topicContent"  v-for="(item, i) in dynamicValidateForm.list2">
							    <!--多选题-->
							    <div class="answer-type">
										<div style="padding-bottom: 5px;"><span class="question-index">{{i+1}}.</span>({{item.score}}分) {{item.title}}</div>
										<el-checkbox-group
												style="width: 100%;"
												class="fd--column display--flex"
												v-model="multiValue"
												v-for="index in item.contentlist" 
												:key="index">
												<el-checkbox 
													:label="index" 
													disabled style="width: 100%; 
													margin-bottom: 20px;">{{index}}</el-checkbox> 
										</el-checkbox-group>
							     </div>
									<div class="button-style">
									<div class="btn-div"><el-tag>我的答案</el-tag>{{item.answer2}}<br></div>
									<div class="btn-div"><el-tag type="info">正确答案</el-tag>{{item.answer}}<br></div>
									<div class="btn-div"><el-tag type="warning">查看解析</el-tag>{{item.reference}}</div>
									</div>
							  </template>
							</div>
							
								<!-- 填空题 -->
							<div class="exercise-center" v-if="dynamicValidateForm.list3 > 0">
							            <!--题目信息-->
								<div style="height: 80px">
									<template>
							      <el-row>
							          <el-col :span="2" style="padding-left: 23px;">
							              <div style="width: 56px;height: 56px;">
															<img src="../../../assets/image/favicon.png" style="width: 100%;"/>
														</div>
							          </el-col>
							          <el-col :span="22" style="padding-top: 2px; padding-left: 16px">
							              <el-row>
							                  <el-col :span="5">题目类型：<span class="topic-style">{{dynamicValidateForm.ttype3}}</span></el-col>
							              </el-row>
							              <el-row class="topic-information">
							                  <el-col :span="5">每题({{dynamicValidateForm.questionscores3}})分共({{dynamicValidateForm.questionscore3}})分</el-col>
							              </el-row>
							          </el-col>
							      </el-row>
									</template>
							  </div>
							  <!--具体题目内容-->
							  <template class="topicContent"  v-for="(item, i) in dynamicValidateForm.list3">
							    <!--简答题-->
							    <div class="answer-type">
										<div style="padding-bottom: 5px;"><span class="question-index">{{i+1}}.</span>({{item.score}}分) {{item.title}}</div>
							      <el-input 
											type="textarea" 
											:autosize="{ minRows: 5, maxRows: 5}" 
											placeholder="请输入..."
											disabled
											style="padding-top: 10px"></el-input>
							     </div>
									 <div class="button-style">
									<div class="btn-div"><el-tag>我的答案</el-tag>{{item.answer2}}<br></div>
									<div class="btn-div"><el-tag type="info">正确答案</el-tag>{{item.answer}}<br></div>
									<div class="btn-div"><el-tag type="warning">查看解析</el-tag>{{item.reference}}</div>
									 </div>
							  </template>
							</div>
							
							<div class="exercise-center" v-if="dynamicValidateForm.list4 > 0">
							            <!--题目信息-->
								<div style="height: 80px">
									<template>
							      <el-row>
							          <el-col :span="2" style="padding-left: 23px;">
							              <div style="width: 56px;height: 56px;">
															<img src="../../../assets/image/favicon.png" style="width: 100%;"/>
														</div>
							          </el-col>
							          <el-col :span="22" style="padding-top: 2px; padding-left: 16px">
							              <el-row>
							                  <el-col :span="5">题目类型：<span class="topic-style">{{dynamicValidateForm.ttype4}}</span></el-col>
							              </el-row>
							              <el-row class="topic-information">
							                  <el-col :span="5">每题({{dynamicValidateForm.questionscores4 }})分共({{ dynamicValidateForm.questionscore4 }})分</el-col>
							              </el-row>
							          </el-col>
							      </el-row>
									</template>
							  </div>
							  <!--具体题目内容-->
							  <template class="topicContent" v-for="(item, i) in dynamicValidateForm.list4">
							    <!--填空题-->
							    <div class="answer-type">
										<div style="padding-bottom: 5px;"><span class="question-index">{{i+1}}.</span>({{item.score}}分) {{item.title}}</div>
							      <el-input 
											type="textarea" 
											:autosize="{ minRows: 5, maxRows: 5}" 
											placeholder="请输入..."
											disabled
											style="padding-top: 10px"></el-input>
							     </div>
									 <div class="button-style">
										<div class="btn-div"><el-tag>我的答案</el-tag>{{item.answer2}}<br></div>
										<div class="btn-div"><el-tag type="info">正确答案</el-tag>{{item.answer}}<br></div>
										<div class="btn-div"><el-tag type="warning">查看解析</el-tag>{{item.reference}}</div>
									 </div>
							  </template>
							</div>
							
							<div class="exercise-center" v-if="dynamicValidateForm.list5 > 0">
							            <!--题目信息-->
								<div style="height: 80px">
									<template>
							      <el-row>
							          <el-col :span="2" style="padding-left: 23px;">
							              <div style="width: 56px;height: 56px;">
															<img src="../../../assets/image/favicon.png" style="width: 100%;"/>
														</div>
							          </el-col>
							          <el-col :span="22" style="padding-top: 2px; padding-left: 16px">
							              <el-row>
							                  <el-col :span="5">题目类型：<span class="topic-style">{{dynamicValidateForm.ttype5}}</span></el-col>
							              </el-row>
							              <el-row class="topic-information">
							                  <el-col :span="5">每题({{dynamicValidateForm.questionscores5}})分共({{dynamicValidateForm.questionscore5}})分</el-col>
							              </el-row>
							          </el-col>
							      </el-row>
									</template>
							  </div>
							  <!--具体题目内容-->
							  <template class="topicContent"  v-for="(item, i) in dynamicValidateForm.list5">
							    <!--判断题题-->
									<div class="radio-type">
											<div style="padding-bottom: 5px;"><span class="question-index">{{i+1}}.</span>({{item.score}}分) {{item.title}}</div>
											<el-radio-group 
													v-for="index in item.contentlist" 
													:key="index">
													<el-radio :label="index" disabled>{{index}}</el-radio>
											</el-radio-group>
									</div>
									<div class="button-style">
										<div class="btn-div"><el-tag>我的答案</el-tag>{{item.answer2}}<br></div>
										<div class="btn-div"><el-tag type="info">正确答案</el-tag>{{item.answer}}<br></div>
										<div class="btn-div"><el-tag type="warning">查看解析</el-tag>{{item.reference}}</div>
									</div>
							  </template>
							</div>
		        </div>
						
					</div>
				</div>
		</el-drawer>
  </div>
</template>


<script>
import { _coursetype , _ttype} from "@/assets/js/filedValueFlag";
import Export from "@/components/Export/index.vue";
import Import from "@/components/Import/index.vue";
import Time from "@/components/Time/index.vue";
import { part } from "@/utils/mixins";
import { mapActions } from "vuex";
import {
  businessexams_list,
	user_dialoguerrsult6,
	businessexams_save,
	examsettings_dialogresult,
	businessexams_input,
	businessexams_delete,
	businessexams_show,
	businessexamsdetailed_start,
	businessexamsdetailed_submit,
	businessexamsdetailed_score,
	
	
	
	
	question_dialogresult2,
	examsettings_input,
	examsettings_delete,
	examsettings_save,
	examsettingsdetailed_list,
	examsettingsdetailed_save,
	examsettingsdetailed_input,
	examsettingsdetailed_delete,
  usergroup_save,
  usergroup_input,
  usergroup_delete,
  usergroup_show,
  
  usergroup_save2,
} from "@/request/api/allChildrenProject";
import {
  _pictureupload_save,
} from "@/request/api/public";
import { myRequest } from "@/request/index";
import {
  selectCheck,
  confirmCallBack,
  messageTip,
  createObjParams,
  formatDare,
} from "@/utils/util";

export default {
  mixins: [part],
	components: {
    Import,
		Time
  },
  props: {
    user_dialoguerrsult6: {
      default: () => user_dialoguerrsult6
    },
		examsettings_dialogresult: {
		  default: () => examsettings_dialogresult,
		},
  },
  data() {
    return {
			selectValue: [],
			aminationForm: {}, //考试详情
      searchForm: {},
      tableRes: {}, //列表接口
      pageinfo: {
        pageindex: 1,
        pagesize: 10,
      }, //页数查询
			selChoice: [],
			questiontypeList: [],
			rules: {
				companyorgname: [{ trigger: "blur", message: "必填字段", required: true }],
				schoolorgname: [{ trigger: "blur", message: "必填字段", required: true }],
				examname: [{ trigger: "blur", message: "必填字段", required: true }],
				questionname: [{ trigger: "blur", message: "必填字段", required: true }],
				examinationtime: [{ trigger: "blur", message: "必填字段", required: true }],
				remarks: [{ trigger: "blur", message: "必填字段", required: true }],
				ttype:[{ trigger: "blur", message: "必填字段", required: true }], 
				questionscore: [{ trigger: "blur", message: "必填字段", required: true }], 
				questionquantity: [{ trigger: "blur", message: "必填字段", required: true }], 
				sort: [{ trigger: "blur", message: "必填字段", required: true }], 
			},
			multipleSelection: [], //选中的表格项
    	showAddModal: false, //添加
			addShowAddModal: false, //新增添加
    	dynamicValidateForm: {
	  	}, //表单
			showCollegenameModal: false, //题库
			myForm: {},
    	dialogTitle: "", //对话框标题
			showDetailModal: false, //详情
			showexamModal: false, //组卷
			showTable: {},
			videoForm: {},
			videocoursedetialModal: false,
			importSaveUrl: "",
      downloadUrl: "",
			showTestModal: false, //试卷
			importDialog: false, //导入框
      selectSearchForm: {}, // 选择搜索
      selectPageInfo: {
        pageindex: 1,
        pagesize: 10,
      }, // 选择框分页
      selectTableUrl: "",
			detailForm: {},
			istype: _ttype,
			rjDetailTable: {}, //流水账
			rjDetailPage: {
			  pageindex: 1,
			  pagesize: 10,
			},
			checkData: [],
			checking: false,
			name: "",
			examtime: "",
			title: "",
			customColor: "#55b6da",
			percentag: 0,
			submitView: false,
			full_score: "",
			list: [],
			scoreList: [],
			checkBoxData: [], //多选
			allRadiobox: [], //多选
			signView: false, // 正确与错误
			passView: false, // 通过或者未通过
			checkResult: false, // 左侧栏、右侧栏、答题结果栏
			scoreAddModal: false, //改卷
			options: [
			  {
			    value: "0",
			    label: "全部"
			  },
			  {
			    value: "1",
			    label: "答对"
			  },
			  {
			    value: "2",
			    label: "答错"
			  }
			],
			checkVal: "0",
			examinationData: "",//单选
			examiationInput: "",//简答题
			examinationLinkData:"", //填空题
			examinationerrorData: "", //判断题
			examinamore: "", //多选题
			allRadio: [], //提交给后台的真题数据
			radio: [], //单选真题答案,
			checkLen: [],
			errortion: [], //判断
			obj: { value: "" },
			answersData: [],
			answersInputData: [],
			moranser: [],
			checkInput: [],
			checkInputData: [],
			completion: [],
			completionData: [],
			answerscompData: [],
			answerserrorData: [],
			allComple: [],
			errortionData: [],
			allerror: [],
			answersBoxData: [],
			radioValue: '',// 单选选定值
			multiValue: [],// 多选选定值
    };
  },
  created() {
    this.getDataList();
		this.dic_combox({
		  list: "questiontypeList",
		  typecode: "questiontype",
		  that: this,
		});
		this.org_comboxchild(this.dynamicValidateForm.companyorgid);
  },
  methods: {
    ...mapActions("global", ["onExtendClick", "isExpand"]),
    ...mapActions("selectData", ["dic_combox", "org_comboxchild"]),
		reset() {
			this.searchForm = {
				examname: null,
				examinationtime: null,
				schoolorgname: null,
				id: null,
			}
		},
		//重置
		resetQuery() {
			this.reset();
			this.pageinfo.pageindex = 1;
			this.getDataList();
		},
		querySelect() {
			this.selectSearchForm = {
				realname: null
			}
		},
		handleQuery() {
			this.querySelect();
			this.onSelectData(this.selectTableUrl, null, null)
		},
		ch(ev){
			console.log(ev);
			this.answer2.push(ev.detail.value);
			console.log(this.answer2);
		},

    //搜索 click
    seach() {
      this.pageinfo.pageindex = 1;
      this.getDataList();
    },
    //列表查询 api
    getDataList() {
      myRequest({
        url: businessexams_list,
        data: Object.assign(this.searchForm, this.pageinfo),
      }).then((res) => {
        if (res.data.code === "200") {
          this.tableRes = res.data.data;
        }
      });
    },
    //切换当前页
    handleCurrentChange(val) {
      this.pageinfo.pageindex = val;
      this.getDataList();
    },
    //触发所有btn方法
    onClickBtn(methodsName) {
      this.$options.methods[methodsName].call(this); //btnAdd ... （防止修改this指向）
    },
		//上传文件前 before
    beforeUpload(file) {
      this.fds.append("upfile", file); // 传文件
    },
    //上传文件 upload
    uploadFile(prop) {
      myRequest({
        method: "post",
        url: _pictureupload_save,
        data: this.fds,
      }).then((res) => {
        if (res.data.code === "200") {
          this.dynamicValidateForm[prop] = res.data.url;
        }
      });
    },
		//查看详情 click
		btnShowDetail(id) {
		 this.tabId = id;
		 myRequest({
		   url: businessexamsdetailed_start,
		 	data: {
		 		id: this.tabId
		 	}
		 }).then((res) => {
		   if (res.data.code === "200") {
		 		this.showDetailModal = true;
		    this.aminationForm = res.data.data;
				
				this.aminationForm.ttype1 = res.data.data.list1[0]?.ttype
				this.aminationForm.ttype2 = res.data.data.list2[0]?.ttype
				this.aminationForm.ttype3 = res.data.data.list3[0]?.ttype
				this.aminationForm.ttype4 = res.data.data.list4[0]?.ttype
				this.aminationForm.ttype5 = res.data.data.list5[0]?.ttype
				
				this.aminationForm.questionscores1 = res.data.data.list1[0]?.questionscore
				this.aminationForm.questionscores2 = res.data.data.list2[0]?.questionscore
				this.aminationForm.questionscores3 = res.data.data.list3[0]?.questionscore
				this.aminationForm.questionscores4 = res.data.data.list4[0]?.questionscore
				this.aminationForm.questionscores5 = res.data.data.list5[0]?.questionscore
				
				this.aminationForm.questionscore1 = res.data.data.list1[0]?.questionscore2
				this.aminationForm.questionscore2 = res.data.data.list2[0]?.questionscore2
				this.aminationForm.questionscore3 = res.data.data.list3[0]?.questionscore2
				this.aminationForm.questionscore4 = res.data.data.list4[0]?.questionscore2
				this.aminationForm.questionscore5 = res.data.data.list5[0]?.questionscore2
				
				this.$set(
						this.aminationForm,
						"idslist",
						res.data.data.list1.map((i) => i.id)
					);
				this.$set(
						this.aminationForm,
						"idslist2",
						res.data.data.list2.map((i) => i.id)
					);
				 this.$set(
						this.aminationForm,
						"idslist3",
						res.data.data.list3.map((i) => i.id)
					);
				this.$set(
						this.aminationForm,
						"idslist4",
						res.data.data.list4.map((i) => i.id)
					);
				 this.$set(
				  this.aminationForm,
				  "idslist5",
				  res.data.data.list5.map((i) => i.id)
				);
				res.data.data.list2.forEach(item =>{
					
				  this.$set(item, 'selChoice', []);
				});
		   }
		 });
		},
		//查看试卷
		btnPaperDetail(id) {
			this.tabId = id;
			myRequest({
			  url: businessexams_show,
				data: {
					id: this.tabId
				}
			}).then((res) => {
			  if (res.data.code === "200") {
					this.showTestModal = true;
			    this.dynamicValidateForm = res.data.data;
					this.dynamicValidateForm.ttype1 = res.data.data.list1[0]?.ttype
					this.dynamicValidateForm.ttype2 = res.data.data.list2[0]?.ttype
					this.dynamicValidateForm.ttype3 = res.data.data.list3[0]?.ttype
					this.dynamicValidateForm.ttype4 = res.data.data.list4[0]?.ttype
					this.dynamicValidateForm.ttype5 = res.data.data.list5[0]?.ttype
					
					this.dynamicValidateForm.questionscores1 = res.data.data.list1[0]?.questionscore
					this.dynamicValidateForm.questionscores2 = res.data.data.list2[0]?.questionscore
					this.dynamicValidateForm.questionscores3 = res.data.data.list3[0]?.questionscore
					this.dynamicValidateForm.questionscores4 = res.data.data.list4[0]?.questionscore
					this.dynamicValidateForm.questionscores5 = res.data.data.list5[0]?.questionscore
					
					this.dynamicValidateForm.questionscore1 = res.data.data.list1[0]?.questionscore2
					this.dynamicValidateForm.questionscore2 = res.data.data.list2[0]?.questionscore2
					this.dynamicValidateForm.questionscore3 = res.data.data.list3[0]?.questionscore2
					this.dynamicValidateForm.questionscore4 = res.data.data.list4[0]?.questionscore2
					this.dynamicValidateForm.questionscore5 = res.data.data.list5[0]?.questionscore2
					
			  }
			});
		},
     // 搜索
    selectSeach() {
			this.selectPageInfo.pageindex = 1;
      this.onSelectData(businessexams_show, "showDetailModal", {
        parentid: this.itemRow.id,
      });
    },
    selectQuery() {
			this.detailForm = {
        name: null,
			}
		},
		handleSelectQuery() {
			this.selectSearchForm = {
			  realname: null,
			}
			this.onSelectData(examsettingsdetailed_list, null, null);
		},
		handleQuery() {
			this.selectQuery();
			this.onSelectData(usergroup_show, null,{
        parentid: this.itemRow.id,
		    });
		},
    onCloseSelectadd() {
      this.selectPageInfo.pageindex = 1;
    },
		onConfirmexamname() {
			selectCheck(this.multipleSelection, "选择", false, () => {
				this.$set(
				  this.dynamicValidateForm,
				  "examid",
				  this.multipleSelection[0].id
				);
				this.$set(
				  this.dynamicValidateForm,
				  "examname",
				  this.multipleSelection[0].examname
				);
				this.showexamModal = false;
				this.multipleSelection = [];
				this.querySelect();
			});
		},
		//确认题库 click
		onConfirmquestionname() {
		  selectCheck(this.multipleSelection, "选择", false, () => {
				this.$set(
				  this.dynamicValidateForm,
				  "userid",
				  this.multipleSelection[0].id
				);
				this.$set(
				  this.dynamicValidateForm,
				  "examnameusername",
				  this.multipleSelection[0].realname
				);
				this.showCollegenameModal = false;
				this.multipleSelection = [];
				this.querySelect();
		  });
		},
		 //查看详情的刷新 click
		btnReloadDetial() {
      this.onSelectData(usergroup_show, "showDetailModal", {
        parentid: this.itemRow.id,
      });
    },
		//切换选项表格页
		handleSelectChange(val, data) {
		  this.selectPageInfo.pageindex = val;
		  this.onSelectData(this.selectTableUrl,null,data);
			this.onCloseSelect();
		},
    //添加click
    btnAdd() {
      this.dialogTitle = "添加";
      this.dynamicValidateForm = {
        schoolorgid: this.$store.state.login.userinfo.schoolorgid,
        companyorgid: this.$store.state.login.userinfo.companyorgid,
        companyorgname: this.$store.state.login.userinfo.companyorgname,
        schoolorgname: this.$store.state.login.userinfo.schoolorgname,
      };
      this.org_comboxchild(this.dynamicValidateForm.companyorgid);
      this.showAddModal = true;
    },
    //修改click
    btnEdit() {
      this.dialogTitle = "修改";
      selectCheck(this.multipleSelection, "修改", false, () => {
        this.editRoutePage(this.multipleSelection[0].id);
      });
    },
		 //删除click
		 btnDel() {
      selectCheck(this.multipleSelection, "删除", true, () => {
        confirmCallBack({
          title: "提示",
          content: "此操作将永久删除选中的数据！",
          success: () => {
            this.delTableItem(this.multipleSelection.map((i) => i.id));
          },
          fail: () => {
            messageTip({
              message: "取消操作",
              type: "info",
            });
          },
        });
      });
    },
		btnAddRJ() {
			this.dialogTitle = "添加题库";
		  this.dynamicValidateForm = {
				examid: this.itemRow.id,
			};
			this.addShowAddModal = true;
		},
		// 改卷
		volume() {
			this.dialogTitle = "改卷";
			selectCheck(this.multipleSelection, "改卷", false, () => {
				this.scorePage(this.multipleSelection[0].id);
			})
		},
		scorePage(id) {
		  myRequest({
		    url: businessexams_show,
		    data: {
		      id: id,
		    },
		  }).then((res) => {
		    if (res.data.code === "200") {
					this.dynamicValidateForm = res.data.data;
					
					this.dynamicValidateForm.ttype1 = res.data.data.list1[0]?.ttype
					this.dynamicValidateForm.ttype2 = res.data.data.list2[0]?.ttype
					this.dynamicValidateForm.ttype3 = res.data.data.list3[0]?.ttype
					this.dynamicValidateForm.ttype4 = res.data.data.list4[0]?.ttype
					this.dynamicValidateForm.ttype5 = res.data.data.list5[0]?.ttype
					
					this.dynamicValidateForm.questionscores1 = res.data.data.list1[0]?.questionscore
					this.dynamicValidateForm.questionscores2 = res.data.data.list2[0]?.questionscore
					this.dynamicValidateForm.questionscores3 = res.data.data.list3[0]?.questionscore
					this.dynamicValidateForm.questionscores4 = res.data.data.list4[0]?.questionscore
					this.dynamicValidateForm.questionscores5 = res.data.data.list5[0]?.questionscore
					
					this.dynamicValidateForm.questionscore1 = res.data.data.list1[0]?.questionscore2
					this.dynamicValidateForm.questionscore2 = res.data.data.list2[0]?.questionscore2
					this.dynamicValidateForm.questionscore3 = res.data.data.list3[0]?.questionscore2
					this.dynamicValidateForm.questionscore4 = res.data.data.list4[0]?.questionscore2
					this.dynamicValidateForm.questionscore5 = res.data.data.list5[0]?.questionscore2
					this.scoreAddModal = true;		
					this.$set(
							this.dynamicValidateForm,
							"ids",
							res.data.data.list1.map((i) => i.id)
						);
					this.$set(
							this.dynamicValidateForm,
							"ids",
							res.data.data.list2.map((i) => i.id)
						);
					 this.$set(
							this.dynamicValidateForm,
							"ids",
							res.data.data.list3.map((i) => i.id)
						);
					this.$set(
							this.dynamicValidateForm,
							"ids",
							res.data.data.list4.map((i) => i.id)
						);
					 this.$set(
					  this.dynamicValidateForm,
					  "ids",
					  res.data.data.list5.map((i) => i.id)
					);
					
					this.$set(
							this.dynamicValidateForm,
							"scores",
							res.data.data.list1.map((i) => i.score)
						);
					this.$set(
							this.dynamicValidateForm,
							"scores",
							res.data.data.list2.map((i) => i.score)
						);
					 this.$set(
							this.dynamicValidateForm,
							"scores",
							res.data.data.list3.map((i) => i.score)
						);
					this.$set(
							this.dynamicValidateForm,
							"scores",
							res.data.data.list4.map((i) => i.score)
						);
					 this.$set(
					  this.dynamicValidateForm,
					  "scores",
					  res.data.data.list5.map((i) => i.score)
					);
				}
		  });
		},
		submitFormTest(dynamicValidateForm) {
			console.log(
				this.dynamicValidateForm.list1, 
				this.dynamicValidateForm.list2, 
				this.dynamicValidateForm.list3, 
				this.dynamicValidateForm.list4,
				this.dynamicValidateForm.list5, 
				)
			  // 是否答完
			  let isComplete = true;
			  this.dynamicValidateForm.list1.forEach((val,i) =>{
			    if(val.scores != null){
			      isComplete = false;
			      return;
			    }
			  })
				
				let isComplete2 = true;
				this.dynamicValidateForm.list2.forEach((val,i) =>{
				  if(val.scores != null){
				    isComplete2 = false;
				    return;
				  }
				})
				
				let isComplete3 = true;
				this.dynamicValidateForm.list3.forEach((val,i) =>{
				  if(val.scores != null){
				    isComplete3 = false;
				    return;
				  }
				})
				
				let isComplete4 = true;
				this.dynamicValidateForm.list4.forEach((val,i) =>{
				  if(val.scores != null){
				    isComplete4 = false;
				    return;
				  }
				})
				
				let isComplete5 = true;
				this.dynamicValidateForm.list5.forEach((val,i) =>{
				  if(val.scores != null){
				    isComplete5 = false;
				    return;
				  }
				})
			  if(isComplete && isComplete2 && isComplete3 && isComplete4 && isComplete5){
					this.dynamicValidateForm.id = this.multipleSelection[0].id
					const moreItemKey = [
					  "scores", 
					];
					moreItemKey.map((i) => (this.dynamicValidateForm[i] = []));
					const moreDataKey = ["list1", "list2", "list3", "list4", "list5"];
					
					for (let moreKey in moreDataKey){
					  for(let j in moreItemKey){
					    for(let i in this.dynamicValidateForm[moreDataKey[moreKey]]){
					      for (let key in this.dynamicValidateForm[moreDataKey[moreKey]][i]){
					        if (this.dynamicValidateForm[moreDataKey[moreKey]][i][key] && key === moreItemKey[j]) {
					          //有值才push，否则是空字符串
					          this.dynamicValidateForm[key].push(this.dynamicValidateForm[moreDataKey[moreKey]][i][key]);
					        }
					      }
					    }
					  }
					}
					
					myRequest({
						  url: businessexamsdetailed_score,
							method: "post",
							data: this.$qs.stringify(this.dynamicValidateForm,{
						      arrayFormat: "repeat",
						    }),
						}).then((res) => {
						  if (res.data.code === "200") {
								this.dynamicValidateForm = {};
								this.scoreAddModal = false;
							}
						});
			  }else{
					// alert('未打完,请继续答卷!');
			    messageTip({
			      message: "请填写未填完的分数~",
			      type: "warning",
			    });
			  }
		},
		//修改详情
		btneditDetail() {
			this.dialogTitle = "修改题库";
			selectCheck(this.multipleSelection, "修改", false, () => {
			  this.editdetialPage(this.multipleSelection[0].id);
			});
		},
		editdetialPage(id) {
		  myRequest({
		    url: examsettingsdetailed_input,
		    data: {
		      id: id,
		    },
		  }).then((res) => {
		    if (res.data.code === "200") {
					this.dynamicValidateForm = res.data.data.vexamsettingsdetailed;
					this.addShowAddModal = true;
				}
		  });
		},

		//单选题
			getIputValue(index) { 
				console.log(index, '这个是单选')
				this.allRadio[index] = this.radio[index]; // 将数据存入提交给后台的数据中
				console.log(this.allRadio, '这个是---',this.allRadio[index], this.radio[index]);
		  },
			// 简答题
			getInput(index) { 
				this.allInput[index] = this.checkInput[index];
				console.log(this.allInput, '这个是--this.checkInput[index]')
			},
			// 多选题
			getcheckValue(index, i) {
				this.allRadiobox[index]	= this.aminationForm.list2[index].selChoice.join('、'),
				console.log(
					this.allRadiobox,
				'这个是--')
			},
			// 填空题
			handleComple(index) {
				this.allComple[index] = this.completion[index];
				console.log(this.allComple, 'v')
			},
			geterror(index) {
				this.allerror[index] = this.errortion[index];
				console.log(this.allerror, '[[]]')
			},
		//这个是提交的页面
		submitHandleTest(aminationForm) {
			this.aminationForm.id = this.tabId
			this.aminationForm.answer2 = this.allRadio.concat(this.allInput, this.allRadiobox, this.allComple, this.allerror)
			this.aminationForm.ids = this.aminationForm.idslist.concat(this.aminationForm.idslist2, this.aminationForm.idslist3, this.aminationForm.idslist4, this.aminationForm.idslist5)
			
			delete this.aminationForm.idslist
			delete this.aminationForm.idslist2
			delete this.aminationForm.idslist3
			delete this.aminationForm.idslist4
			delete this.aminationForm.idslist5
			myRequest({
				method: "post",
				url: businessexamsdetailed_submit,
				data: this.$qs.stringify(this.aminationForm, {
					arrayFormat: 'repeat'
			}),
			},{
			  isRefTableList: true,
			  that: this,

			}).then((res) => {
			  if (res.data.code === "200") {
					this.showDetailModal = false;
				}
			});
		},
		//删除 api
    delTableItem(idArr) {
      myRequest(
        {
          method: "post",
          url: businessexams_delete,
          data: this.$qs.stringify(
            {
              ids: idArr,
            },
            {
              arrayFormat: "repeat",
            }
          ),
        },
        {
          isRefTableList: true,
          that: this,
        }
      );
    },
    //刷新 click
    btnReload() {
      this.getDataList();
    },
		//选项表格 - 搜索 click
		onSelectSearchClick(url, modal, data) {
		  this.selectPageInfo.pageindex = 1;
		  this.onSelectData(url, modal, data);
		},
    //选项表格 api
    onSelectData(url, modal, data) {
      this.selectTableUrl = url;
      myRequest({
        url: this.selectTableUrl,
        data: Object.assign(data || {}, this.selectPageInfo),
      }).then((res) => {
        if (res.data.code === "200") {
          this.$refs.multipleTable && this.$refs.multipleTable.clearSelection();
          this.selectTable = res.data.data;
          this[modal] = true;
        }
      });
    },
    //关闭选项框 click
    onCloseSelect(){
      this.selectPageInfo.pageindex = 1
    },
    //修改路由页 api
    editRoutePage(id) {
      myRequest({
        url: businessexams_input,
        data: {
          id: id,
        },
      }).then((res) => {
        if (res.data.code === "200") {
					this.showAddModal = true;
					this.dynamicValidateForm = res.data.data.vbusinessexams;
				}
      });
    },
		submitaddForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          delete this.videoForm.inserttime;
					myRequest({
					url: examsettingsdetailed_save,
					data: this.dynamicValidateForm,
						},
						{
			  		isRefTableList: true,
			  		that: this,

			  		modal: "addShowAddModal",
					}).then((res) => {
            if(res.data.code == "200") {
              this.onSelectData(examsettingsdetailed_list, "showDetailModal", {
                parentid: this.itemRow.id,
              });
            }
          })
        }
      });
    },
		 //提交-（添加，修改） api
		 submitHandleForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
					myRequest({
					url: videocoursedetailed_savechapter,
					data: {
						videocourseid: this.itemRow.id,
						chaptername2: this.myForm.chaptername2
					}
					},
					{
			  		isRefTableList: true,
			  		that: this,

			  		modal: "addShowAddModal",
				})
        }
      });
    },
    //提交-（添加，修改） api
    submitAddOrEditForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
					this.submitForm();
        }
      });
    },
		//提交-（添加，修改） api
		submitForm() {
			myRequest({
				method: "post",
				url: businessexams_save,
				data: this.$qs.stringify(this.dynamicValidateForm, {
					arrayFormat: 'repeat'
			}),
			},{
			  isRefTableList: true,
			  that: this,
			
			  modal: "showAddModal",
			})
		},
    //选中表格数据改变时
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
  },
};
</script>



<style lang="scss">

</style>